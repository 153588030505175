














































import { OrdemServicoService } from "@/core/services/residuo";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DetalharResiduoDestinacao extends Vue {

   overlay: boolean = false;
   service: OrdemServicoService = new OrdemServicoService();
   item: any = {
      items: [],
      total: 0
   };
   filtro: any = {
      numOS: null,
      numOD: null,
      clienteId: null,
      embarcacaoId: null,
      mr1: null,
      residuoId: null,
      dataInicio: null,
      dataFim: null     
   }

   mounted(){
      this.Carregar();
   }

   created() {
      this.filtro.numOS = this.$route.query.numOS,
      this.filtro.numOD = this.$route.query.numOD,
      this.filtro.clienteId = this.$route.query.clienteId,
      this.filtro.embarcacaoId = this.$route.query.embarcacaoId,
      this.filtro.mr1 = this.$route.query.mr1,
      this.filtro.residuoId = this.$route.query.residuoId,
      this.filtro.dataInicio = this.$route.query.dataInicio,
      this.filtro.dataFim = this.$route.query.dataFim
   }

   Carregar(){
      this.overlay = true;
      this.service.RelatorioDestinacao(this.filtro.numOS, this.filtro.numOD, this.filtro.clienteId, this.filtro.embarcacaoId, this.filtro.mr1, this.filtro.residuoId, this.filtro.dataInicio, this.filtro.dataFim).then(
         res => {
            this.item = res.data;
         },
         err => {
            this.$swal('Aviso', err.message, 'error');
         }
      ).finally(() => {
         this.overlay = false;
      })
   }
}
